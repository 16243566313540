.text-field {
  max-width: 345px;
}
.text-field:hover {
  border-color: #d9dee5;
}
.text-field:focus, .text-field:active {
  border-color: #0062ff;
}
.text-field:disabled, .text-field:read-only {
  background-color: #f5f7f9;
  color: #9caab8;
  border-color: #e7e9f2;
  cursor: default;
}