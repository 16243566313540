.dragdrop-wrapper .euiDraggable--m {
  padding: 0;
  border: none;
}
.dragdrop-wrapper .toc-generated-content-nav-wrapper .group-wrapper .nav-item-wrapper .nav-item {
  width: 100%;
  max-width: calc(100% - 25px);
}
.dragdrop-wrapper .toc-generated-content-nav-wrapper .group-wrapper .nav-item-wrapper .close.dropdown-icon-button,
.dragdrop-wrapper .toc-generated-content-nav-wrapper .group-wrapper .nav-item-wrapper .generated-content-edit-button {
  display: none;
}
.dragdrop-wrapper .toc-generated-content-nav-wrapper .group-wrapper .nav-item-wrapper:hover .close.dropdown-icon-button,
.dragdrop-wrapper .toc-generated-content-nav-wrapper .group-wrapper .nav-item-wrapper:hover .generated-content-edit-button {
  display: block;
}
.dragdrop-wrapper .toc-generated-content-nav-wrapper .error {
  border-color: red;
  box-shadow: 0 0 5px red;
}

.extra-drop-space {
  height: 10px;
  display: none;
}