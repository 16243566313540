.toggle-switch-input {
  display: none;
}
.toggle-switch-input, .toggle-switch-input:after, .toggle-switch-input:before, .toggle-switch-input *, .toggle-switch-input *:after, .toggle-switch-input *:before, .toggle-switch-input + .toggle-switch {
  box-sizing: border-box;
}
.toggle-switch-input::selection, .toggle-switch-input:after::selection, .toggle-switch-input:before::selection, .toggle-switch-input *::selection, .toggle-switch-input *:after::selection, .toggle-switch-input *:before::selection, .toggle-switch-input + .toggle-switch::selection {
  background: none;
}
.toggle-switch-input + .toggle-switch {
  outline: 0;
  display: block;
  width: 40px;
  height: 20px;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding: 2px;
  transition: all 0.2s ease;
  background: #fff;
  border: 2px solid #9caab8;
  border-radius: 4px;
}
.toggle-switch-input + .toggle-switch:after, .toggle-switch-input + .toggle-switch:before {
  position: relative;
  display: block;
  content: "";
  width: 12px;
  height: 12px;
}
.toggle-switch-input + .toggle-switch:after {
  left: 0;
  transition: all 0.2s ease;
  background: #9caab8;
  content: "";
  border-radius: 3px;
}
.toggle-switch-input + .toggle-switch:before {
  display: none;
}
.toggle-switch-input + .toggle-switch.sm {
  width: 32px;
  height: 16px;
  padding: 1.5px;
}
.toggle-switch-input + .toggle-switch.sm:after, .toggle-switch-input + .toggle-switch.sm:before {
  width: 9.6px;
  height: 9.6px;
}
.toggle-switch-input:checked + .toggle-switch {
  background: #28c397;
  border-color: #28c397;
}
.toggle-switch-input:checked + .toggle-switch:after {
  left: 62%;
  background: #fff;
}
.toggle-switch-input:disabled + .toggle-switch {
  cursor: default;
}