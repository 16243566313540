.content-section-wrapper .content-box {
  min-height: 148px;
  flex: 1 0 calc(50% - 80px);
}
.content-section-wrapper .content-box .edit-icon {
  margin-top: 6px;
  margin-right: 13px;
  display: none;
}
.content-section-wrapper .content-box:hover .edit-icon {
  display: block;
}
.content-section-wrapper .add-section-wrapper {
  max-width: calc(50% - 24px);
}
.content-section-wrapper .add-section-wrapper .add-content-button {
  max-width: 300px;
  color: #0c7ff2;
}
.content-section-wrapper .add-section-wrapper .add-content-button:hover {
  background: transparent;
}