.slug-input-field-wrapper {
  min-width: 150px;
  margin-right: 15px;
}
.slug-input-field-wrapper .slug-input-field.disabled {
  min-width: 300px;
}

.slug-input-field-wrapper input,
.slug-input-field-disabled-wrapper input {
  max-width: inherit;
}

.slug-input-field-disabled-wrapper {
  flex: 1;
}