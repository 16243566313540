.notification {
  display: block;
  font-size: 14px;
  width: 500px;
  height: 35px;
  line-height: 25px;
  padding: 5px 0;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -90px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #28c397;
  color: #fff;
  text-align: center;
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.2);
}
.notification.error {
  background-color: #ff4451;
}

.animate-open {
  -webkit-animation: moveOpen 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
}

/* Safari and Chrome */
@-webkit-keyframes moveOpen {
  from {
    -webkit-transform: translate(0, -100px);
  }
  10% {
    -webkit-transform: translate(0, 20px);
  }
  12% {
    -webkit-transform: translate(0, 22px);
  }
  16% {
    -webkit-transform: translate(0, 20px);
  }
  80% {
    -webkit-transform: translate(0, 20px);
  }
  85% {
    -webkit-transform: translate(0, 25px);
  }
  to {
    -webkit-transform: translate(0, -100px);
  }
}