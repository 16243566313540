.logo-wrapper .branding-logo {
  border: 1px dashed #d9dee5;
}
.logo-wrapper .logo-delete-button {
  background-color: #dfe2e5;
}
.logo-wrapper .image-preview {
  width: 148px;
  height: 148px;
}
.logo-wrapper .image-preview img {
  max-width: 140px;
}
.logo-wrapper .file-button {
  max-width: 148px;
}
.logo-wrapper .euiFilePicker__prompt {
  height: 50px;
}
.logo-wrapper .euiFilePicker__prompt svg {
  display: none;
}