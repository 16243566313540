.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #455666 transparent #455666 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.s {
  width: 8px;
  height: 8px;
}
.lds-dual-ring.s:after {
  width: 8px;
  height: 8px;
}

.lds-dual-ring.m {
  width: 16px;
  height: 16px;
}
.lds-dual-ring.m:after {
  width: 16px;
  height: 16px;
}

.lds-dual-ring.l {
  width: 24px;
  height: 24px;
}
.lds-dual-ring.l:after {
  width: 24px;
  height: 24px;
}

.lds-dual-ring.xl {
  width: 32px;
  height: 32px;
}
.lds-dual-ring.xl:after {
  width: 32px;
  height: 32px;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}