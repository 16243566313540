select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='10px' viewBox='0 0 24 24' preserveAspectRatio='xMidYMid' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='none' style='display: inline-block; '%3E%3Cpath stroke='%239CAAB8' stroke-linecap='round' stroke-linejoin='round' stroke-width='4' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 10px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  padding-left: 0.75rem;
  padding-right: 1.6rem;
}

select.sm {
  background-position-y: 4px;
  background-position-x: calc(100% - 4px);
}