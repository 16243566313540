.color-selector-wrapper {
  position: relative;
}
.color-selector-wrapper .color-item {
  width: 26px;
  height: 26px;
  padding: 4px;
  border-radius: 50%;
  position: relative;
}
.color-selector-wrapper .active {
  border: 1px solid #d1d1d1;
}
.color-selector-wrapper .color-selector-button,
.color-selector-wrapper .color-selected-input {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.color-selector-wrapper .open-button {
  background: url("../../assets/imgs/wheel-5-ryb.png") no-repeat;
}
.color-selector-wrapper button {
  outline: none;
}
.color-selector-wrapper .euiColorPicker {
  background: #ffffff;
  box-shadow: 0px 3px 6px #ced2e2;
  border-radius: 4px;
  width: 160px;
  padding: 4px;
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 1;
}