.homepage-logo-wrapper img {
  height: 50px;
}
.homepage-logo-wrapper .file-button {
  max-width: 148px;
}
.homepage-logo-wrapper .euiFilePicker__prompt {
  height: 50px;
}
.homepage-logo-wrapper .euiFilePicker__prompt svg {
  display: none;
}