.checkbox-wrapper {
  height: 24px !important;
}
.checkbox-wrapper label {
  margin: 0;
  height: 22px;
  display: flex;
  align-items: center;
}
.checkbox-wrapper [type=checkbox] {
  width: 0.1px;
  height: 0.1px !important;
  opacity: 0;
  margin-bottom: 0;
  display: inline-block;
  cursor: text;
  border: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  /* background-clip: padding-box; */
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  margin-top: 0;
}
.checkbox-wrapper .label {
  margin: 0 0 0 5px !important;
  cursor: pointer;
  color: #252525;
  font-size: 14px;
  padding-right: 0px;
}
.checkbox-wrapper [type=checkbox]:checked + .checkbox,
.checkbox-wrapper [type=checkbox]:not(:checked) + .checkbox {
  border-radius: 2px;
  width: 14px;
  height: 14px;
  display: inline-block;
  border: 2px solid #28c397;
  background-color: #28c397;
  cursor: pointer;
  margin-left: 3px;
}
.checkbox-wrapper [type=checkbox]:checked + .checkbox svg,
.checkbox-wrapper [type=checkbox]:not(:checked) + .checkbox svg {
  margin: 2px auto 0;
}
.checkbox-wrapper [type=checkbox]:not(:checked) + .checkbox {
  border: 2px solid #9CAAB8;
  background-color: white;
}
.checkbox-wrapper [type=checkbox]:checked + .checkbox {
  border: 2px solid #28c397;
  background-color: #28c397;
}
.checkbox-wrapper [type=checkbox]:disabled + .checkbox {
  opacity: 0.5;
  cursor: no-drop;
}