.jumbotron-wrapper {
  height: 340px;
  background: url("../../../../assets/imgs/cover-image.png") no-repeat;
  background-size: cover;
  border-radius: 0px 0px 12px 12px;
  background-color: rgba(0, 0, 0, 0.25);
  position: relative;
}
.jumbotron-wrapper .content-box {
  max-width: 525px;
  z-index: 2;
}
.jumbotron-wrapper .content-box:hover {
  background-color: rgba(36, 49, 62, 0.5);
}
.jumbotron-wrapper .content-box:hover .edit-icon {
  opacity: 1;
}
.jumbotron-wrapper .upload-button {
  margin-bottom: 12px;
  margin-left: 12px;
  z-index: 2;
}
.jumbotron-wrapper .add-button {
  flex: 1 0 calc(50% - 80px);
  margin: 20px;
  z-index: 2;
}

.jumbotron-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.z-2 {
  z-index: 2;
}