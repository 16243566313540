.radio-button-wrapper {
  height: 21px;
}
.radio-button-wrapper .radio-button-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  display: inline-block;
}
.radio-button-wrapper .radio-button-input:disabled + .radio-button,
.radio-button-wrapper .radio-button-input:disabled + .radio-button .icon {
  background: #fff;
  cursor: default;
}
.radio-button-wrapper .radio-button-input:disabled + .radio-button + .label {
  cursor: default;
}
.radio-button-wrapper :checked + .radio-button .icon {
  width: 6px;
  height: 6px;
  background: #28c397;
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
  display: block;
  border-radius: 50%;
}
.radio-button-wrapper .radio-button {
  width: 14px;
  height: 14px;
  border: 2px solid #9caab8;
  background: transparent;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.radio-button-wrapper :checked + .radio-button {
  border: 2px solid #28c397;
}
.radio-button-wrapper .label {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  margin-left: 8px;
  cursor: pointer;
}