.confirm-unsaved-changes-wrapper .modal {
  background-color: #0c7ff2;
  box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1), 0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1), 0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
  border-color: #e3e4e8 #c6cad1 #aaafba;
  border-style: solid;
  border-width: 1px;
  color: white;
  border-radius: 4px;
  z-index: 8000;
  min-width: 400px;
  -webkit-animation: modalAnimation 0.35s cubic-bezier(0.34, 1.61, 0.7, 1);
  animation: modalAnimation 0.35s cubic-bezier(0.34, 1.61, 0.7, 1);
}
.confirm-unsaved-changes-wrapper .modal .modal-body {
  padding: 8px 24px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 75vh;
  overflow: hidden;
}
.confirm-unsaved-changes-wrapper .modal .modal-footer {
  padding: 16px 24px 24px;
}
.confirm-unsaved-changes-wrapper button {
  outline: none;
  margin-left: 20px;
}
.confirm-unsaved-changes-wrapper .ghost {
  background-color: #006bb4;
  color: white;
}
.confirm-unsaved-changes-wrapper .overlay {
  position: fixed;
  z-index: 6000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 10vh;
  -webkit-animation: fadeInAnimation 150ms ease-in;
  animation: fadeInAnimation 150ms ease-in;
  background: rgba(255, 255, 255, 0.8);
}

@-webkit-keyframes modalAnimation {
  0% {
    -webkit-transform: translateY(32px);
    opacity: 0;
    transform: translateY(32px);
  }
  to {
    -webkit-transform: translateY(0);
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes modalAnimation {
  0% {
    -webkit-transform: translateY(32px);
    opacity: 0;
    transform: translateY(32px);
  }
  to {
    -webkit-transform: translateY(0);
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}