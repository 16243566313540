.side-nav-wrapper {
  border-right: 1px solid #e7e9f2;
  min-width: 270px;
  z-index: 1;
}
.side-nav-wrapper hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.side-nav-wrapper .side-nav-group-title {
  margin-top: 3rem;
}
.side-nav-wrapper .list-item:first-child {
  margin-top: 1rem;
}
.side-nav-wrapper .list-item:first-child .side-nav-group-title {
  margin-top: 0rem;
}
.side-nav-wrapper .menu-item-wrapper button {
  outline: none;
}
.side-nav-wrapper .menu-item-wrapper .icon,
.side-nav-wrapper .menu-item-wrapper .menu-item {
  display: inline-block;
}
.side-nav-wrapper .pl0-6 {
  padding-left: 0.6rem;
}
.side-nav-wrapper .text-sm {
  font-size: 12px;
  color: #9caab8;
  font-weight: 500;
}

.version-selector-wrapper .version-select {
  max-width: 70px;
  min-width: 56px;
  height: 19px;
  padding: 0 4px;
  font-size: 12px;
  line-height: 16px;
}