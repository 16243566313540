.dropdown-wrapper {
  max-width: 34px;
  height: 40px;
  float: right;
}
.dropdown-wrapper .rotate-90 {
  transform: rotate(90deg);
}
.dropdown-wrapper hr {
  margin: 0px 8.5px;
  border-top: 1px solid #e7e9f2;
}
.dropdown-wrapper.sm {
  max-height: 20px;
}
.dropdown-wrapper .dropdown-menu {
  min-width: 154px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #ced2e2;
  border-radius: 3px;
  list-style: none;
  padding: 0;
  position: absolute;
  right: 0;
  margin-top: 0;
  z-index: 1;
}
.dropdown-wrapper .dropdown-menu .dropdown-item {
  background: #ffffff;
  height: 40px;
  cursor: pointer;
}
.dropdown-wrapper .dropdown-menu .dropdown-item > a {
  color: #282d44;
  text-decoration: none;
  line-height: 40px;
  padding: 0 12px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dropdown-wrapper .dropdown-menu .dropdown-item > a .icon {
  width: 24px;
  display: inline-block;
}
.dropdown-wrapper .dropdown-menu .dropdown-item > a .item-text {
  display: inline-block;
}
.dropdown-wrapper .dropdown-menu .dropdown-item.active {
  font-weight: bold;
}
.dropdown-wrapper .dropdown-menu .dropdown-item:hover {
  background: #f5f7f9;
}
.dropdown-wrapper .dropdown-menu .dropdown-item .disabled {
  background: #9caab8;
}
.dropdown-wrapper .dropdown-submenu {
  position: relative;
}
.dropdown-wrapper .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}