.publishing-sdk-card.greenBorder {
  border: 2px solid #28c397;
}
.publishing-sdk-card h3 {
  padding-bottom: 16px;
}
.publishing-sdk-card p {
  padding-bottom: 16px;
}
.publishing-sdk-card .publishing-sdk-ul {
  list-style: disc;
  list-style-position: inside;
}
.publishing-sdk-card .publishing-sdk-ul li {
  padding-bottom: 12px;
  padding-left: 10px;
}