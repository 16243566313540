@import url("https://fonts.googleapis.com/css?family=Rubik:100,100i,300,300i,400,400i,500,500i,700,700i,900,900iz");
*,
*:before,
*:after {
  font-family: "Rubik", sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  transition: visibility 0ms ease;
  -webkit-transition: visibility 0ms ease;
  -moz-transition: visibility 0ms ease;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
#root {
  min-height: 100vh;
  line-height: 1.25;
}

img {
  border: none;
}

.flex-item-es {
  flex-grow: 1;
  flex-basis: 0;
}

.flex-flow-row-wrap {
  flex-flow: row wrap;
}

.close-icon {
  top: 12px;
  right: 12px;
}

.drag-icon {
  top: 8px;
  left: 50%;
  transform: translateY(50%);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.text-ellipsis {
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.fade-in-animation {
  animation-duration: 0.5s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: fadeIn;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.active {
  color: #0c7ff2;
}
.active > div {
  color: #0c7ff2;
}

.active svg,
.active svg .header-icon-fill,
.active svg .folder-icon-fill {
  fill: #0c7ff2 !important;
}

.custom-scroll {
  -webkit-overflow-scrolling: touch;
}
.custom-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.custom-scroll::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: transparent;
}
.custom-scroll:hover::-webkit-scrollbar-thumb, .custom-scroll:focus::-webkit-scrollbar-thumb {
  background: #e7e9f2;
}
.custom-scroll:hover.invert-scroll::-webkit-scrollbar-thumb, .custom-scroll:focus.invert-scroll::-webkit-scrollbar-thumb {
  background: #3d4c6a;
}

.table-basic-layout tr {
  border-bottom: 1px solid #e7e9f2;
}
.table-basic-layout td,
.table-basic-layout th {
  text-align: left;
  padding: 8px 16px;
  vertical-align: middle;
}
.table-basic-layout th {
  color: #9caab8;
  text-transform: uppercase;
  font-size: 12px;
}
.table-basic-layout td {
  font-size: 14px;
}

#notification-portal {
  z-index: 2;
  left: calc(50vw - 150px);
  transform: translateX(50%);
}

.lh-21 {
  line-height: 21px;
}

.blur-overflow {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.blur-overflow:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 80%, white);
}

.drop-shadow {
  box-shadow: 0px 3px 6px #ced2e2 !important;
}

input,
textarea {
  outline-color: #0c7ff2;
}

.min-w-5 {
  min-width: 5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}