.button-wrapper {
  min-width: 78px;
  min-height: 32px;
  padding: 6px 12px;
  display: flex;
  color: #24313e;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  border: none;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}
.button-wrapper:disabled {
  opacity: 0.7;
}
.button-wrapper.icon-fix {
  min-width: 32px;
  padding: 6px 8px;
}
.button-wrapper.icon-fix .icon {
  margin-right: 8px;
}
.button-wrapper.sm {
  min-width: 23px;
  min-height: 26px;
  border-radius: 3px;
}
.button-wrapper.sm.icon-fix {
  padding: 5px;
}
.button-wrapper.xs {
  min-width: 20px;
  min-height: 20px;
  border-radius: 3px;
}
.button-wrapper.xs.icon-fix {
  padding: 3px;
}
.button-wrapper.danger {
  background-color: transparent;
}
.button-wrapper.danger:hover:not([disabled]) {
  background-color: rgba(255, 68, 81, 0.1);
  color: #ff4451;
}
.button-wrapper.transparent {
  background-color: transparent;
}
.button-wrapper.transparent:hover:not([disabled]) {
  background-color: #f5f7f9;
}
.button-wrapper.primary {
  background-color: #28c397;
  color: #fff;
}
.button-wrapper.primary:hover:not([disabled]) {
  background-color: #23ba8f;
}
.button-wrapper.success {
  background-color: rgba(40, 195, 151, 0.1);
  color: #28c397;
}
.button-wrapper.success:hover:not([disabled]) {
  background-color: rgba(40, 195, 151, 0.2);
}
.button-wrapper.error {
  background-color: rgba(255, 68, 81, 0.1);
  color: #ff4451;
}
.button-wrapper.error:hover:not([disabled]) {
  background-color: rgba(255, 68, 81, 0.2);
}
.button-wrapper.default {
  background-color: #f5f7f9;
}
.button-wrapper.default:hover:not([disabled]) {
  background-color: #e1e7ed;
}
.button-wrapper.tertiary {
  background-color: #24313e;
  color: #fff;
}
.button-wrapper.tertiary:hover:not([disabled]) {
  background-color: #24313e;
}
.button-wrapper.link {
  background-color: transparent;
  color: #0c7ff2;
}
.button-wrapper.link:hover:not([disabled]) {
  background: transparent;
}
.button-wrapper.raised-link {
  background-color: #f5f7f9;
  color: #0c7ff2;
}
.button-wrapper.raised-link:hover:not([disabled]) {
  background: #e1e7ed;
}
.button-wrapper:focus, .button-wrapper:hover, .button-wrapper:active {
  outline: none;
}