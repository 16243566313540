.icon-wrapper .content {
  min-width: 45px;
}

.pre-release-tag {
  font-size: 8px;
  justify-content: center;
  background-color: #ff4451;
  border-radius: 4px;
  padding: 2px 4px;
  color: white;
  bottom: -20px;
  white-space: nowrap;
}