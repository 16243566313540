.tooltip {
  position: relative;
}

.tooltip-trigger {
  display: block;
}

.tooltip-bubble {
  width: max-content;
  min-width: 120px;
  max-width: 145px;
  position: absolute;
  z-index: 10;
}
.tooltip-bubble::after {
  content: "";
  position: absolute;
}

.tooltip-top {
  padding-bottom: 8px;
  transform: translate(-50%, -100%);
}
.tooltip-top::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #24313e;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom {
  padding-top: 8px;
  transform: translateX(-50%);
}
.tooltip-bottom::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #24313e;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-left {
  padding-right: 8px;
  transform: translate(-100%, -50%);
}
.tooltip-left::after {
  border-left: 8px solid #24313e;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.tooltip-right {
  padding-left: 8px;
  transform: translateY(-50%);
}
.tooltip-right::after {
  border-right: 8px solid #24313e;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.tooltip-message {
  background: #24313e;
  border-radius: 3px;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1.4;
  padding: 11px 10px;
  text-align: center;
}