.overlay {
  position: fixed;
  z-index: 3;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
}

.guideQuickView {
  position: fixed;
  z-index: 3;
  min-width: 740px;
  bottom: 50px;
  right: 50px;
  transform: scale(0.8);
  transform-origin: 100% 100%;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  max-width: inherit !important;
  width: 70vw !important;
  min-width: 1024px !important;
}
.guideQuickView .preview-wrapper {
  min-height: 600px !important;
  height: 70vh;
}
.guideQuickView .preview-card-wrapper {
  min-height: 600px !important;
  height: 70vh;
}

@media only screen and (max-width: 1000px), screen and (max-height: 670px) {
  .guideQuickView {
    transform: scale(0.5);
  }
}
@media only screen and (max-width: 1000px), screen and (max-height: 669px) {
  .guideQuickView {
    transform: scale(0.68);
  }
}
.guideQuickView.opaque {
  opacity: 1;
  visibility: visible;
}

.guideQuickViewToggle.opaque {
  display: none;
}

.guideQuickViewToggle {
  outline: none;
  position: fixed;
  right: 50px;
  bottom: 50px;
  cursor: pointer;
  font-weight: 600;
  z-index: 2;
  width: 200px !important;
}
.guideQuickViewToggle:hover, .guideQuickViewToggle:focus {
  outline: none;
}
.guideQuickViewToggle .card-wrapper {
  border-radius: 6px;
  padding: 1rem;
}