.typography-section .typography-section-toggle {
  right: 0px;
}
.typography-section .typography-section-toggle .chevron-icon {
  display: inline-block;
  transform: rotate(180deg);
  margin-left: 3px;
}

.typograph-option-wrapper:last-child > div {
  border: 0px;
}

.fontSettings {
  margin-bottom: 20px;
}
.fontSettings > span {
  margin-right: 40px;
}