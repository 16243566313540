.widget-wrapper {
  height: calc(100vh - 72px);
  position: fixed;
  left: 0;
  top: 72px;
}
.widget-wrapper .widget-centered {
  width: 566px;
  height: 650px;
}
.widget-wrapper .widget-content .widget-body .code-block,
.widget-wrapper .widget-content .widget-body .code-block-wrapper {
  height: 340px;
}

.publish-option-card {
  background: rgba(217, 222, 229, 0.18);
}
.publish-option-card .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 46px;
  height: 46px;
  background: rgba(12, 127, 242, 0.1);
  border-radius: 50%;
}
.publish-option-card:hover {
  background-color: #0c7ff2;
}
.publish-option-card:hover p {
  color: white;
}
.publish-option-card:hover .icon-wrapper {
  background: rgba(255, 255, 255, 0.2);
}
.publish-option-card:hover .icon-wrapper svg g g line,
.publish-option-card:hover .icon-wrapper svg g g g,
.publish-option-card:hover .icon-wrapper svg g g path {
  stroke: white;
}

.copy-snippet {
  position: absolute;
  bottom: 30px;
  left: 20px;
}

.white-label {
  line-height: 20px;
}
.white-label a {
  color: #0c7ff2;
}

.version-selector tbody:before {
  content: "@";
  display: block;
  line-height: 0.75rem;
  text-indent: -99999px;
}