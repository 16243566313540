.preview-card-wrapper {
  background-color: white;
  min-height: calc(100vh - 70px);
}

.preview-wrapper {
  min-height: 682px;
  height: calc(100vh - 74px);
}
.preview-wrapper .header {
  height: 82px;
}
.preview-wrapper .header .logo-wrapper img {
  height: 35px;
}
.preview-wrapper .main-wrapper {
  height: calc(100% - 82px);
}
.preview-wrapper .main-wrapper .sidebar-wrapper {
  background-color: #f5f7f9;
  width: 20%;
  min-width: 220px;
}
.preview-wrapper .main-wrapper .sidebar-wrapper .custom-guides {
  height: calc(100% - 140px);
}
.preview-wrapper .main-wrapper .content-section-wrapper {
  width: 45%;
}
.preview-wrapper .main-wrapper .code-samples-wrapper {
  width: 35%;
  background-color: #10244c;
}
.preview-wrapper .main-wrapper .code-samples-wrapper .custom-scroll {
  padding: 20px;
}
.preview-wrapper .main-wrapper .code-samples-wrapper pre {
  overflow-x: initial !important;
  background-color: transparent !important;
}
.preview-wrapper .main-wrapper .code-samples-wrapper .code {
  height: calc(100% - 65px);
}
.preview-wrapper .main-wrapper .code-samples-wrapper .code:hover {
  overflow: auto;
}
.preview-wrapper .main-wrapper .code-samples-wrapper .try-out-btn {
  background-color: #213b70;
}