.breadcrumbs .breadcrumbs-item {
  margin-right: 8px;
  font-size: 0.875rem;
  line-height: 1.5;
}
.breadcrumbs .breadcrumbs-item a {
  color: #6a717d;
}
.breadcrumbs .breadcrumbs-item span {
  font-weight: 500;
}
.breadcrumbs .seperator {
  flex-shrink: 0;
  pointer-events: none;
  display: inline-block;
  margin-right: 8px;
  width: 1px;
  height: 16px;
  -webkit-transform: translateY(0.2em) rotate(15deg);
  transform: translateY(0.2em) rotate(15deg);
  background: #d3dae6;
}