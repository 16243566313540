.badge-wrapper {
  min-width: 30px;
  max-width: 70px;
  padding: 0 11px;
  height: 19px;
  border-radius: 6px;
  background: #6b7e8f;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
}
.badge-wrapper .badge-title {
  color: #fff;
  font-size: 9px;
  line-height: 19px;
  font-weight: 600;
}
.badge-wrapper.success {
  background: #28c397;
}