.badge-selector-wrapper {
  height: 232px;
}
.badge-selector-wrapper .add-badge-action {
  color: #0c7ff2;
}
.badge-selector-wrapper .add-badge-action:hover {
  background: transparent;
}
.badge-selector-wrapper .badges-list {
  height: 170px;
}